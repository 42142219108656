<template>
  <v-container>
    <v-btn
      class="my-5 start-btn"
      color="light-green"
      block
      @click="confirmStartDelivery()"
    >
      Iniciar reparto
    </v-btn>
    <v-divider></v-divider>
    <v-btn class="my-5" @click="$router.push('/deliverypoints')" block>
      Editar reparto
    </v-btn>
    <v-btn class="my-5" @click="$router.push('/newspapers')" block>
      Editar periódicos
    </v-btn>
    <confirm-dialog
      v-model="confirmDialog.show"
      @ok="startDelivery()"
      @cancel="confirmDialog.show = false"
    >
      ¿ {{ confirmDialog.message }} ?
      <template v-slot:content>
        <div class="d-flex justify-end">
          <v-checkbox
            label="Festivo"
            v-model="confirmDialog.holiday"
          ></v-checkbox>
        </div>
      </template>
    </confirm-dialog>
  </v-container>
</template>

<script>
import ConfirmDialog from "../components/ui/ConfirmDialog.vue";

export default {
  name: "Home",
  components: {
    ConfirmDialog,
  },
  data: () => ({
    confirmDialog: {
      message: "",
      show: false,
      holiday: false,
    },
  }),

  computed: {
    today: () => {
      return new Date().getDay()
    }
  },

  methods: {
    confirmStartDelivery() {
      const days = [
        "Domingo",
        "Lunes",
        "Martes",
        "Miercoles",
        "Jueves",
        "Viernes",
        "Sabado",
      ];
      this.confirmDialog.message = `Iniciar reparto ${days[this.today]}`;
      this.confirmDialog.show = true;
    },

    startDelivery() {
      this.$router.push('/deliverypoints/init-delivery/')
    },
  },
};
</script>

<style scoped>
.start-btn {
  min-height: 100px;
}
</style>
