<template>
  <v-app>
    <v-app-bar
      class="d-flex justify-center"
      app
      color="primary"
      dark
    >
      <div>
        <h3>Reparto App</h3>
      </div>
    </v-app-bar>

    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  components: {
  },

  data: () => ({
  }),

};
</script>
