export default {
    name: '',
    quantity: 1,
    dayOfWeek: [0,1,2,3,4,5,6],
    holidays: false,
    active: true,
    isNew: true,
    props: { 
        color: ''
    }
}
