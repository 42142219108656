<template>
    <v-dialog
      v-model="show"
      max-width="400"
    >
      <v-card>
        <v-card-title class="headline d-flex justify-center">
           <slot></slot>
        </v-card-title>
        <v-card-text>
          <slot name="content"></slot>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :color="buttonsProps.ok.color"
            text
            @click="$emit('ok')"
          >
            {{ buttonsProps.ok.message }}
          </v-btn>
          <v-btn
            :color="buttonsProps.cancel.color"
            text
            @click="$emit('cancel')"
          >
            {{ buttonsProps.cancel.message }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'ConfirmDialog',
    props: [
      'value',
      'buttons'],
    computed: {

        show: {
            get() {
                return this.value
            },
            set (value) {
                this.$emit('input', value)
            }
        },

        buttonsProps() {
            return {
                ok: {
                    message: 'Aceptar',
                    color: 'primary'
                },
                cancel: {
                    message: 'Cancelar',
                    color: 'grey'
                },
                ...this.buttons
            }
        }
    }
}
</script>